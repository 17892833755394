<template>
    <div class="system-check-page" v-if="activeOrg && info && info.needSwitch">
        <div class="switch-org-wrapper">
            <div class="switch-header">{{$t('system.check_page_switch_header')}}</div>
            <div class="switch-body fonts-14">
                <div class="text-center">{{$t('system.check_page_switch_message_1')}} {{activeOrg.organizationName}}</div>
                <div class="text-center margin-t-15">{{$t('system.check_page_switch_message_2')}}<span class="margin-x-5 text-bold">{{info.organizationName}}</span> {{$t('system.check_page_switch_message_3')}}</div>
                <div class="text-center margin-t-30">
                    <el-button round plain size="mini" :disabled="loading" @click="cancelBtnEvent">{{$t('system.check_page_switch_button_1')}}</el-button>
                    <el-button round type="primary" size="mini" :loading="loading" @click="switchBtnEvent">{{$t('system.check_page_switch_button_2')}}</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { commonApi, orgApi } from '@/utils/api';
import { mapState } from 'vuex';
import qs from 'querystring';
import tools from '@/utils/tools';
export default {
    data() {
        return {
            info: null,
            loading: false,
        }
    },
    computed: {
        ...mapState({
            activeOrg: state => state.org.activeOrg,
            userInfo: state => state.user.userData
        })
    },
    methods: {
        async initCheck() {
            await tools.sleep(1000);
            let query = this.$route.query;
            if (query.source && query.source === 'crm') {
                if (this.userInfo.internalOrganizationId === this.activeOrg.organizationId) {
                    this.$router.replace({path: '/project/create', query});
                }else {
                    let url = `${orgApi.queryOrgInfoNoToken}?organizationId=${this.userInfo.internalOrganizationId}`;
                    this.$ajax.get(url).then(res => {
                        if (res.status === 200) {
                            this.info = {
                                ...res.data,
                                needSwitch: true,
                                organizationName: res.data.fullName,
                                targetTo: `/project/create?${qs.stringify(query)}`,
                            };
                        }
                    }).finally(() => {

                    })
                }
            }else if (query.token) {
                let url = commonApi.queryCheckAndSwitchInfo;
                this.$ajax.post(url, query).then(res => {
                    if (res.status === 200) {
                        this.info = res.data;
                        if (!this.info.needSwitch) {
                            location.replace(this.info.targetTo);
                        }
                    }
                }).finally(() => {

                }).catch(e => {
                    this.$router.replace('/');
                });
            }else {
                this.$router.replace('/');
            }
        },
        cancelBtnEvent() {
            this.$router.replace('/');
        },
        switchBtnEvent() {
            let url = orgApi.transferOrg;
            this.loading = true;
            this.$ajax.post(url, {
                organizationId: this.info.organizationId,
            }).then(async res => {
                if(res.status === 200){
                    await tools.sleep(1000);
                    location.replace(this.info.targetTo);
                }
            }).finally(() => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.initCheck();
    }
}
</script>
<style lang="scss" scoped>
.system-check-page {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .switch-org-wrapper { 
        width: 430px;
        background-color: #fff;
        border-radius: 10px;
        .switch-header { 
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            box-sizing: border-box;
            border-bottom: 1px solid #e8e8e8;
        }
        .switch-body {
            padding: 30px 20px;

        }
    }
}
</style>
